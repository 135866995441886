import { toast } from "react-toastify";
import axiosClient from "../utils/axios.tsx";
import Cookies from "js-cookie";

class User {
  async loginUser(values) {
    try {
      const user = await axiosClient().post("users/log-in", values);
      return user.data;
    } catch (error) {
      if (error.response?.data?.message === "Account removed") {
        throw new Error("ACCOUNT_REMOVED");
      } else {
        console.error(error);
        toast.error(error.response?.data?.message || "An error occurred");
      }
      throw error;
    }
  }

  async googleLogin(values) {
    try {
      const user = await axiosClient().get("/users/google/sign-in", {
        headers: values,
      });

      return user.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");

      console.log("err=>", error);

      return error;
    }
  }

  async getUserByToken() {
    const id = Cookies.get("affiliate-user-id");
    try {
      if (id) {
        const user = await axiosClient().get(`users/${id}`);
        return user.data;
      }
    } catch (error) {
      console.error("error", error);

      throw new Error(error);
    }
  }
}

export const usersApi = new User();

import React, { useState } from "react";
import { Logo } from "../../../components/index.ts";
import { Typography, Button, Spin } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/auth-context.tsx";

const LoginFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
});

const CustomIcon = () => {
  return (
    <img
      src="https://ik.imagekit.io/8extk8sjo/Social%20icon.svg?updatedAt=1709103355258"
      alt=""
      width={`25px`}
    />
  );
};

const LoginForm = () => {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { signIn, googleSignIn } = useAuthContext();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await googleSignIn(tokenResponse.access_token);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
      console.log("Google Authentication Failed");
      toast.error("Google Authentication Failed");
    },
  });

  const handleGoogleLoginClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    googleLogin();
  };

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
      <div className="w-full flex items-center justify-center pt-12 signupForm">
        <div className="w-[50%] h-full signinForm" style={{ width: "360px" }}>
          <Logo />
          <Title className="pt-20 font-sans " style={{ fontWeight: "600" }}>
            Welcome Back
          </Title>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginFormSchema}
            onSubmit={async (values) => {
              await signIn(values.email.toLocaleLowerCase(), values.password); //sending false to indicate that this is not a google login
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="pt-4">
                  <div>
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px]"
                    >
                      Email*
                    </Title>
                    <Field
                      placeholder="Enter your email"
                      label="Email*"
                      name="email"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal lowercase"
                    />
                    <div className="h-[16px]">
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <div className="text-red-500  text-[12px] h-[5px] ">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px] "
                    >
                      Password*
                    </Title>
                    <div className="relative">
                      <Field
                        type={show ? "text" : "password"}
                        name="password"
                        placeholder="Enter your password"
                        className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                      />
                      <div
                        onClick={() => setShow(!show)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400"
                      >
                        {show ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                      </div>
                    </div>
                    <div className="h-[10px]">
                      <ErrorMessage
                        name="password"
                        render={(msg) => (
                          <div className="text-red-500 text-[12px] h-[5px]">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full font-sans h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
                    disabled={isSubmitting} // Disable button while submitting
                  >
                    {isSubmitting ? <Spin /> : "Login"}
                  </Button>
                  <Button
                    icon={<CustomIcon />}
                    onClick={handleGoogleLoginClick}
                    className="w-full font-sans h-[44px] bg-[#fff] mt-4 shadow-none border-[1.5px] border-[#D0D5DD] rounded-[8px] font-semibold text-[16px] text-[#344054] flex items-center justify-center gap-1 "
                    disabled={isLoading} // Disable button while submitting
                  >
                    {isLoading ? <Spin /> : "Login with Google"}
                  </Button>

                  <div className="text-left  passwords">
                    <Text
                      style={{
                        color: "#475467",
                      }}
                      className=" text-[14px] font-sans"
                    >
                      Forgot password?{" "}
                      <span
                        onClick={() => navigate("/forget-password")}
                        className="text-[#004EEB] cursor-pointer font-semibold text-[14px] font-sans"
                      >
                        Reset password
                      </span>
                    </Text>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="text-center mt-3 ">
            <Text
              style={{
                color: "#475467",
              }}
              className=" text-[14px] font-sans"
            >
              Don't have an account?
              <span
                onClick={() => navigate("/signup")}
                className="text-[#004EEB] pl-1 cursor-pointer font-semibold text-[14px] font-sans"
              >
                Sign up
              </span>
            </Text>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginForm;
